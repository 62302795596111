.copy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-top: 1px solid #ddd;
  font-size: 1rem;

  img {
    width: 18px;
    height: 18px;
  }

  @media(max-width: 320px) {
    flex-direction: column;
    gap: 10px;
  }

  strong {
    text-transform: uppercase;
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}