.cookiesWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba(39, 39, 39, 0.9);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  @media (max-width: 575px) {
    flex-direction: column;
    gap: 15px;
    align-items: flex-end;
  }

  p {
    color: #fff;
    font-size: 1.4rem;
    display: flex;
    gap: 15px;
    max-width: 60%;
    align-items: center;

    @media (max-width: 991px) {
      max-width: 100%;
    }
  }

  svg {
    min-width: max-content;
    font-size: 3rem;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
    color: #f8b600;
  }

  button {
    font-family: var(--headingFont);
    background: #fff;
    padding: 10px 20px;
    border-radius: 5px;
  }
}
