.logoLink {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 2.4rem;
  line-height: 2rem;
  font-weight: 700;
  font-family: var(--headingFont);

  img {
    min-width: 32px;
  }

  span {
    display: inline-block;
    max-width: 75px;
    text-transform: uppercase;

    @media screen and (max-width: 399px) {
      display: none;
    }
  }
}
