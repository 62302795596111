.siteLinks {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-family: var(--headingFont);

  p {
    font-size: 1.6rem;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 1.4rem;

    @media (max-width: 475px) {
      flex-direction: column;
    }
  }

  li {
    position: relative;
    z-index: 0;

    & + li::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -12.5px;
      transform: translate(0, -50%);
      width: 5px;
      height: 5px;
      background: var(--default-purple);
      border-radius: 100%;

      @media (max-width: 475px) {
        top: -12.5px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }

  .googlesf {
    line-height: 0;
  }
}